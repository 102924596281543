import React from "react"
import { Link } from "gatsby"

import "../styles/globals.scss"

const NotFound = () => {
  return (
      <div className="container p-10">
        <h1 className="mb-5">Page not Found.</h1>
        <p>
          <strong>
            Go to <Link to="/">Home Page</Link>.
          </strong>
        </p>
      </div>
  )
}

export default NotFound
